<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <volver-parte-checklist-menu-items
        slot="menuItemsLongPress"
        :idparte-trabajo="idsParaVolerAtras.idparte_trabajo"
        :idchecklist-ot="idsParaVolerAtras.idchecklist_ot"
        :idorden-trabajo-matsist="idsParaVolerAtras.idorden_trabajo_matsist"
        :idparte-trabajo-matsist="idsParaVolerAtras.idparte_trabajo_matsist"
        :idsubsis="idsParaVolerAtras.idsubsis"
      />
    </b10-toolbar>
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
        :more-info-in-dialog="false"
      />
      <b10-fab-button
        :icon="$vuetify.icons.values.edit"
        @click="clickEdit"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import Data from './MaterialSistemaViewData'
import { TDATO_ARTICULO } from '@/utils/consts'
import VolverParteChecklistMenuItems from '../components/VolverParteChecklistMenuItems'
import { isValidDate, toDate } from '@/utils/date'

export default {
  components: {
    VolverParteChecklistMenuItems,
  },
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      moreInfoRows: [
        { name: 'material_sistema.codigo', label: 'Código' },
        { name: 'material_sistema.descripcion', label: 'Descripción' },
        { name: 'material_sistema.unidades', label: 'Unidades' },
        { name: 'material_sistema.fsistema', label: 'Fecha de instalación', filter: this.$options.filters.shortDate },
        { name: 'timportancia_material.descripcion', label: 'Importancia' },
        { name: 'material_sistema.nserie', label: 'Nº de serie' },
        { name: 'material_sistema.nelemento', label: 'Nº de elemento' },
        { name: 'material_sistema.ubicacion', label: 'Ubicación' },
        { name: 'material_sistema.netiqueta', label: 'Nº de etiqueta' },
        { name: 'material_sistema.num_zona', label: 'Nº de zona' },
        { name: 'material_sistema.evento', label: 'Evento' },
        { name: 'material_sistema.funcion', label: 'Función' },
        { name: 'material_sistema.particion', label: 'Partición' },
        { name: 'material_sistema.fult_revision', label: 'Última revisión', filter: this.$options.filters.shortDate },
        // { name: '', label: 'Próxima revisión', filter: this.$options.filters.shortDate },
        { name: 'material_sistema.fult_retimbrado', label: 'Último retimbrado', filter: this.$options.filters.shortDate },
        // { name: '', label: 'Próximo retimbrado', filter: this.$options.filters.shortDate },
        { name: 'material_sistema.fcaducidad', label: 'Fecha de caducidad', filter: this.$options.filters.shortDate },
        { name: 'material_sistema.ffin_garantia', label: 'Fin de garantía', filter: this.$options.filters.shortDate },
        { name: 'fabricante.descripcion', label: 'Fabricante' },
        { name: 'material_sistema.ffabricacion', label: 'Fecha de fabricación', filter: this.$options.filters.shortDate },
        { name: 'agente_extintor.descripcion', label: 'Agente extintor' },
        { name: 'agente_propelente.descripcion', label: 'Agente propelente' },
        { name: 'material_sistema.volumen', label: 'Volumen' },
        { name: 'material_sistema.peso_total', label: 'Peso total' },
        { name: 'material_sistema.peso_agente_extintor', label: 'Peso agente extintor' },
        { name: 'material_sistema.eficacia', label: 'Eficacia' },
        { name: 'material_sistema.presion', label: 'Presión' },
        { name: 'material_sistema.observaciones', label: 'Observaciones' },
      ],
      showingDialogs: {
        moreInfo: false,
      },
      tsubsis: {},
      idsParaVolerAtras: {
        idparte_trabajo: null,
        idchecklist_ot: null,
        idorden_trabajo_matsist: null,
        idparte_trabajo_matsist: null,
        idsubsis: null,
      },
    }
  },
  computed: {
    formattedItem () {
      if (!_.isEmpty(this.item.dataset) && !_.isEmpty(this.tsubsis)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.material_sistema.idmaterial_sistema
        item.title = `${item.material_sistema.descripcion} (${item.material_sistema.codigo})`
        item.subtitle = ''
        if (this.tsubsis.tsubsis.ver_mat_nelemento) {
          item.badge = item.material_sistema.nelemento
        } else if (this.tsubsis.tsubsis.ver_mat_ubicacion) {
          item.badge = item.material_sistema.ubicacion
        } else {
          item.badge = ''
        }
        item.badgeColor = 'info'
        item.alerts = []
        if (item.material_sistema.fdesinstalacion) {
          item.alerts.push({
            value: `Material DENSINSTALADO el ${this.$options.filters.shortDate(item.material_sistema.fdesinstalacion)}`,
            type: 'warning'
          })
        }
        this.setMoreInfoRowValue('material_sistema.nserie', 'visible', this.tsubsis.tsubsis.ver_mat_nserie)
        this.setMoreInfoRowValue('material_sistema.nelemento', 'visible', this.tsubsis.tsubsis.ver_mat_nelemento)
        this.setMoreInfoRowValue('material_sistema.ubicacion', 'visible', this.tsubsis.tsubsis.ver_mat_ubicacion)
        this.setMoreInfoRowValue('material_sistema.netiqueta', 'visible', this.tsubsis.tsubsis.ver_mat_netiqueta)
        this.setMoreInfoRowValue('material_sistema.num_zona', 'visible', this.tsubsis.tsubsis.ver_mat_num_zona)
        this.setMoreInfoRowValue('material_sistema.evento', 'visible', this.tsubsis.tsubsis.ver_mat_evento)
        this.setMoreInfoRowValue('material_sistema.funcion', 'visible', this.tsubsis.tsubsis.ver_mat_funcion)
        this.setMoreInfoRowValue('material_sistema.particion', 'visible', this.tsubsis.tsubsis.ver_mat_particion)
        this.setMoreInfoRowValue('material_sistema.fult_revision', 'visible', this.tsubsis.tsubsis.ver_mat_fult_revision)
        // (no existe aún en offline, es un campo calculado en el servidor)
        // this.setMoreInfoRowValue('', 'visible', this.tsubsis.tsubsis.ver_mat_fprox_revision)
        this.setMoreInfoRowValue('material_sistema.fult_retimbrado', 'visible', this.tsubsis.tsubsis.ver_mat_fult_retimbrado)
        // (no existe aún en offline, es un campo calculado en el servidor)
        // this.setMoreInfoRowValue('', 'visible', this.tsubsis.tsubsis.ver_mat_fprox_retimbrado)
        this.setMoreInfoRowValue('material_sistema.fcaducidad', 'visible', this.tsubsis.tsubsis.ver_mat_fcaducidad)
        this.setMoreInfoRowValue('material_sistema.ffin_garantia', 'visible', this.tsubsis.tsubsis.ver_mat_ffin_garantia)
        this.setMoreInfoRowValue('fabricante.descripcion', 'visible', this.tsubsis.tsubsis.ver_mat_idfabricante)
        this.setMoreInfoRowValue('material_sistema.ffabricacion', 'visible', this.tsubsis.tsubsis.ver_mat_ffabricacion)
        this.setMoreInfoRowValue('agente_extintor.descripcion', 'visible', this.tsubsis.tsubsis.ver_mat_idagente_extintor)
        this.setMoreInfoRowValue('agente_propelente.descripcion', 'visible', this.tsubsis.tsubsis.ver_mat_idagente_propelente)
        this.setMoreInfoRowValue('material_sistema.volumen', 'visible', this.tsubsis.tsubsis.ver_mat_volumen)
        this.setMoreInfoRowValue('material_sistema.peso_total', 'visible', this.tsubsis.tsubsis.ver_mat_peso_total)
        this.setMoreInfoRowValue('material_sistema.peso_agente_extintor', 'visible', this.tsubsis.tsubsis.ver_mat_peso_agente_extintor)
        this.setMoreInfoRowValue('material_sistema.eficacia', 'visible', this.tsubsis.tsubsis.ver_mat_eficacia)
        this.setMoreInfoRowValue('material_sistema.presion', 'visible', this.tsubsis.tsubsis.ver_mat_presion)
        item.moreInfo = this.buildMoreInfo()
        return item
      } else {
        return {}
      }
    },
  },
  // routeQuery.idparte_trabajo: si se viene de un parte que se pueda volver hacia el parte
  async created () {
    await this.initStore()
    await this.initDB()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.title = this.item.dataset.material_sistema.descripcion
      this.tsubsis = await this.$offline.tsubsis.row(this.item.dataset.subsis.idtsubsis)
      // datos dinámicos de ficha técnica
      const tdatosArticulo = await this.$offline.articulo.selectTDatoArticulo(this.item.dataset.articulo.idficha_tecnica)
      for (let tdato of tdatosArticulo) {
        const datoMaterialSistema = await this.$offline.materialSistema.selectDatoMaterialSistemaValor(
          this.item.dataset.material_sistema.idmaterial_sistema, tdato.tdato_articulo.idtdato_articulo
        )
        let valor = ''
        // casos especiales por existir valores no estandarizados
        if (datoMaterialSistema.length > 0) {
          valor = datoMaterialSistema[0].dato_material_sistema.valor
          // fechas
          if (tdato.tdato_articulo.tipo_valor === TDATO_ARTICULO.tipos.fecha && valor) {
            const valorDate = toDate(valor)
            // si es un día válido
            if (isValidDate(valorDate)) {
              valor = this.$options.filters.shortDate(valorDate)
            }
          }
        }
        this.moreInfoRows.push({
          name: tdato.tdato_articulo.idtdato_articulo,
          label: tdato.tdato_articulo.descripcion,
          value: valor,
        })
      }
      if (this.routeQuery.idparte_trabajo){
        this.idsParaVolerAtras.idparte_trabajo = this.routeQuery.idparte_trabajo
        this.idsParaVolerAtras.idsubsis = this.item.dataset.subsis.idsubsis
        const parteTrabajo = await this.$offline.parteTrabajo.row(this.routeQuery.idparte_trabajo)
        const parteTrabajoMatsist = await Data.selectParteTrabajoMatsist(
          this,
          this.item.dataset.material_sistema.idmaterial_sistema,
          this.routeQuery.idparte_trabajo
        )
        this.idsParaVolerAtras.idparte_trabajo_matsist = parteTrabajoMatsist.parte_trabajo_matsist.idparte_trabajo_matsist
        const checklistOt = await this.$offline.checklistOt.selectChecklistOtDeIdOrdenTrabajo(parteTrabajo.parte_trabajo.idorden_trabajo)
        let datasetChecklist = []
        if (checklistOt) {
          this.idsParaVolerAtras.idchecklist_ot = checklistOt.checklist_ot.idchecklist_ot
          datasetChecklist = await this.$offline.parteTrabajoMatsist.selectChecklistCount(
            parteTrabajoMatsist.parte_trabajo_matsist.idparte_trabajo_matsist,
            checklistOt.checklist_ot.idchecklist_ot,
          )
        }
        if (datasetChecklist?.totalPreguntas > 0) {
          // este id es para volver al listado de preguntas
          this.idsParaVolerAtras.idorden_trabajo_matsist = parteTrabajoMatsist.orden_trabajo_matsist.idorden_trabajo_matsist

        }
      }
    },
    async loadItem () {
      this.loadingData = true
      try {
        const dataset = await Data.selectMaterialSistema(this, this.routeParams.idmaterial_sistema)
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async clickEdit () {
      this.$appRouter.push({
        name: 'offline__material-sistema-edit',
        params: {
          idmaterial_sistema: this.item.dataset.material_sistema.idmaterial_sistema,
        },
      })
    },
  }
}
</script>
